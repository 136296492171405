import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

let title = 'Uncertainties: Bearings';
let metatitle = 'Uncertainties: Bearings';
let description = '';
let metadescription = description;





const Bearings = ({ data }) => {
  
  
  
  
    
    return(
      
        <Layout bodyclass="as-built" title={title}>
          <SEO
            title={metatitle}
            description={metadescription}
          />
          <h2>Uncertainties in Limit State Definition (β<sub>LS</sub>)</h2>
          <div style={{overflowX: 'auto'}}>
          <table className="plaintable">
            <thead>
              <tr>
                <th></th>
                <th>β<sub>LS,1</sub></th>
                <th>β<sub>LS,2</sub></th>
                <th>β<sub>LS,3</sub></th>
                <th>β<sub>LS,4</sub></th>
                <th><span style={{color: '#000'}}><strong>β<sub>LS, mean</sub>*</strong></span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><span style={{color: '#000'}}><strong>Elastomeric Bearings</strong></span></td>
                <td>0.61</td>
                <td>0.22</td>
                <td>0.22</td>
                <td>0.32</td>
                <td><span style={{color: '#000'}}><strong>0.34</strong></span></td>
              </tr>
            </tbody>
          </table>
          </div>
          <em style={{fontSize:12,paddingBottom:30,display:'block'}}>* Values based on statistical analysis of the limit state definitions in literature, available in the database.</em>
          <p style={{marginBottom:10}}>Reference</p>
          <p>Stefanidou, S., Paraskevopoulos E., Papanikolaou, V., Kappos, A. (2021). An online database and software for bridge-specific fragility analysis of as-built and retrofitted bridges.  Bulletin of Earthquake Engineering.</p>
              
        </Layout>
      )
    
}

export default Bearings

